<template>
    <div>
        <header-portrate />

    <div class="pa-2 m-2">
        <bread-crumbs :items="items"></bread-crumbs>
        <tabs-comp :tabs="tabs" />
        <v-row :style="`direction:`+lang.dir+`;margin-left: 30px;margin-right:30px;`">
            <v-col cols="12" md="12" sm="12" :class="`text-`+lang.lalgin">
                <b-button
                    v-b-toggle.add_expense
                    class="btn-sm btn "
                    style="margin-top:11px;width:150px;background:transparent;border:none"
                    id="multiply-button" 
                    >
                    <div style="width:150px;" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_expenses}}</div>
                </b-button>
            </v-col>
        </v-row>
        <v-row :style="`direction:`+lang.dir+`;margin-left: 30px;margin-right:30px;`">
            <v-data-table
                :headers="headers"
                :search="search"
                :items="exprows"
                class="elevation-1"
            >
                <template v-slot:item.action="{ item }">
                     <v-btn class="ma-1" style="width:70px;height:25px;background:red;color:#FFF" 
                        @click="DeleteExpense( item.id )">{{lang.delete}}</v-btn>
                     <v-btn class="ma-1" style="width:70px;height:25px;background:green;color:#FFF" 
                        @click="payit( item )" v-b-toggle.pay_expenses>{{lang.payit}}</v-btn>
                </template>

            </v-data-table>
            
        </v-row>
        <addExpense />
        <PayExpenses ref="payExp" />
    </div>

        <Footer />
    </div>
</template>
<script>
import axios from 'axios';
import addExpense from '../components/addExpense.vue'
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import PayExpenses from '@/components/pay-expenses.vue';
import Footer from '@/components/Footer.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';

export default{
    components: {
      addExpense,
        BreadCrumbs,
        TabsComp,
        PayExpenses,
        Footer,
        HeaderPortrate
    },
    data() {
        return {
            active_tab:4,
            search: '',
           
            
            exprows:[
               
            ],
            exps: {
                yearly: 0,
                half_year: 0,
                quarter: 0,
                monthly: 0,
                daily: 0,
            },
            currentExp: [],
        }
    },
    methods: {
        payit(item){
            this.currentExp = item;
            this.$refs.payExp.getCurretnExp();
        },
        printMe(item){
            const post = new FormData();
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('id',item.id);
        },
        ExportIt(type){
            let url = this.$store.state.SAMCOTEC.e_path+'?';
            if(type != 'e'){
                let url = this.$store.state.SAMCOTEC.pd_path+'?inrecepit';
            }
            const post = new FormData();
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('sdate','');
            post.append('edate','');
            axios.post(
                url, post
            ).then((res) => {
                var rest = res.data;
                window.open('../api' + rest.url,'_blank')
            })
        },
        DeleteExpense(id){
            const post = new FormData();
            post.append("type" , "delExpense");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[id]",id);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    ///this.exprows = res.results.data.results;
                    
                    // console.log(res);
                    this.getExpenses();
                }
            )
        },
        getExpenses(){
            const post = new FormData();
            post.append("type" , "getExpense");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]","all");
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    this.exprows = res.results.data.results;
                }
            )
        }
    },
    created() {
        this.getExpenses();
        // setInterval(() => {
        //     this.getExpenses();
        // }, 10000);
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function(){
            return [
                {
                    index:0,name:this.lang.chart_of_account,href:'/finance/coa',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.finance_jvs,href:'/finance/jvs',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:'أرصدة افتتاحية',href:'/finance/fient',class: 'mytab2',smclass:'font-small'
                },
                // {
                //     index:1,name:this.lang.exchange_voucher,href:'/finance/outreceipt',class: 'mytab2',smclass:'font-small'
                // },
                {
                    index:0,name:this.lang.paytypes,href:'/finance/paytypes',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.fixed_assets,href:'/finance/fixed-assets',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.definition,href:'/finance/definition',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.expenses_summary,href:'/expenses/summary',class: 'mytab4',smclass:'font-small'
                },
                {
                    index:1,name:this.lang.general_expenses,href:'/expenses/general',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:1,name:this.lang.exp_management,href:'/expenses/management',class: 'mytab2',smclass:'font-small'
                },
                {
                    index:1,name:this.lang.recurring_expensess,href:'/expenses/recurring',class: 'mytab',smclass:'font-small'
                },
            ]
        },
        items: function() {
            return {
                    text: this.lang.recurring_expensess,
                    disabled: true,
                    to: '/finance/expenses',
                }
        },
        headers:function() {
            return [
                {
                    text: '',
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: this.lang.expense_type,
                    align: 'center',
                    sortable: false,
                    value: 'expens_type',
                },
                {
                    text: this.lang.yearly,
                    align: 'center',
                    sortable: false,
                    value: 'yearly',
                },
                {
                    text: this.lang.midterm,
                    align: 'center',
                    sortable: false,
                    value: 'half_year',
                },
                {
                    text: this.lang.quarterly,
                    align: 'center',
                    sortable: false,
                    value: 'quarter',
                },
                {
                    text: this.lang.monthly,
                    align: 'center',
                    sortable: false,
                    value: 'monthly',
                },
                {
                    text: this.lang.daily,
                    align: 'center',
                    sortable: false,
                    value: 'daily',
                },
                {
                    text: this.lang.action,
                    align: 'center',
                    sortable: false,
                    value: 'action',
                    class: 'actionWidth',
                },
            ]
        }
    },
}
</script>
<style>
.actionWidth{
    width: 100px !important;
}
</style>